import Vue from 'vue';
import Vuex from 'vuex';

import account from './account';
import agreements from './agreements';
import api from './api';
import application from './application';
import apps from './apps';
import auth from './auth';
import credits from './credits';
import eid from './eid';
import header from './header';
import purchase from './purchase';
import stripe from './stripe';
import yapily from './yapily';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    agreements,
    api,
    application,
    apps,
    auth,
    credits,
    eid,
    header,
    purchase,
    stripe,
    yapily,
  },
});

export default {
  bindValuedParams(params) {
    const obj = {};
    if (!params) {
      return obj;
    }
    Object.keys(params).forEach((k) => {
      if (params[k]) {
        obj[k] = params[k];
      }
    });
    return obj;
  },
};

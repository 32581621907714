import { YAPILY } from '@/constants';

const urlParser = {
  /**
   *
   * @param {Object} query
   */
  scanQueryForErrors(query) {
    if (
      query[YAPILY.QUERY_PARAMS.FAILURE.ERROR] ||
      query[YAPILY.QUERY_PARAMS.FAILURE.ERROR_DESCRIPTION] ||
      query[YAPILY.QUERY_PARAMS.FAILURE.ERROR_SOURCE]
    ) {
      const errorKey = getErrorKey(
        query[YAPILY.QUERY_PARAMS.FAILURE.ERROR_SOURCE],
      );
      const message = YAPILY.ERROR_MESSAGE[errorKey];
      const status = YAPILY.ERROR_CODE[errorKey];
      const urlError = buildUrlError(`${status} - ${message}`, status, {
        [YAPILY.QUERY_PARAMS.FAILURE.ERROR]:
          query[YAPILY.QUERY_PARAMS.FAILURE.ERROR] || undefined,
        [YAPILY.QUERY_PARAMS.FAILURE.ERROR_DESCRIPTION]:
          query[YAPILY.QUERY_PARAMS.FAILURE.ERROR_DESCRIPTION] || undefined,
        [YAPILY.QUERY_PARAMS.FAILURE.ERROR_SOURCE]:
          query[YAPILY.QUERY_PARAMS.FAILURE.ERROR_SOURCE] || undefined,
      });
      throw urlError;
    }
  },
};

export default urlParser;

export const buildUrlError = (message, code, data) =>
  new UrlError(message, code, data);

class UrlError extends Error {
  constructor(message, code, data) {
    super(message);
    this.code = code;
    this.response = new Response(code, data);
  }
}

class Response {
  constructor(status, data) {
    this.status = status;
    this.data = data;
  }
}

const getErrorKey = (source) => {
  let key = YAPILY.ERROR.BAD_REQUEST;
  if (!source) {
    return key;
  }
  for (const k in YAPILY.ERROR_SOURCES) {
    if (YAPILY.ERROR_SOURCES[k].includes(source)) {
      key = k;
      break;
    }
  }
  return key;
};

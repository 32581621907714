import {
  mdiAccountOutline,
  mdiArrowBottomLeftBoldOutline,
  mdiArrowTopRightBoldOutline,
  mdiFileDownloadOutline,
  mdiFolderOpenOutline,
  mdiFountainPenTip,
  mdiPowerCycle,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortCalendarAscending,
  mdiSortCalendarDescending,
  mdiViewGridOutline,
  mdiViewListOutline,
} from '@mdi/js';
import { objectHandler } from '@/utils';

const APIS = Object.freeze({
  ID: {
    COMMERCIO_DOCS: 'mdi-folder-open-outline',
    COMMERCIO_ID: 'mdi-account-outline',
    COMMERCIO_SIGN: 'mdi-fountain-pen-tip',
    COMMERCIO_MINT: 'mdi-power-cycle',
    COMMERCIO_KYC: 'mdi-file-download-outline',
  },
});

const APIS_ICONS = Object.freeze({
  [APIS.ID.COMMERCIO_DOCS]: mdiFolderOpenOutline,
  [APIS.ID.COMMERCIO_ID]: mdiAccountOutline,
  [APIS.ID.COMMERCIO_SIGN]: mdiFountainPenTip,
  [APIS.ID.COMMERCIO_MINT]: mdiPowerCycle,
  [APIS.ID.COMMERCIO_KYC]: mdiFileDownloadOutline,
});

const POSITIONINGS = Object.freeze({
  GRID: 'grid',
  LIST: 'list',
});

const SORTINGS = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
  ALPHABET_ASC: 'alphabet-asc',
  ALPHABET_DESC: 'alphabet-desc',
  DATE_ASC: 'date-asc',
  DATE_DESC: 'date-desc',
});

const STRIPE = Object.freeze({
  STATUS: {
    DRAFT: 'draft',
    OPEN: 'open',
    PAID: 'paid',
    UNCOLLECTIBLE: 'uncollectible',
    VOID: 'void',
  },
});

const TX = Object.freeze({
  MESSAGE_STATUS: {
    ENQUEUED: 'enqueued',
    FAILURE: 'failure',
    PROCESSING: 'processing',
    SUCCESS: 'success',
  },
  TYPE: {
    RECEIVED: 'received',
    SENT: 'sent',
  },
});

const ICONS = Object.freeze({
  [POSITIONINGS.GRID]: mdiViewGridOutline,
  [POSITIONINGS.LIST]: mdiViewListOutline,
  [SORTINGS.ALPHABET_ASC]: mdiSortAlphabeticalAscending,
  [SORTINGS.ALPHABET_DESC]: mdiSortAlphabeticalDescending,
  [SORTINGS.DATE_ASC]: mdiSortCalendarAscending,
  [SORTINGS.DATE_DESC]: mdiSortCalendarDescending,
  [TX.TYPE.RECEIVED]: mdiArrowBottomLeftBoldOutline,
  [TX.TYPE.SENT]: mdiArrowTopRightBoldOutline,
});

const COUNTRIES = Object.freeze({
  IT: 'IT',
});

const PAYMENT_METHODS = {
  BANK_TRANSFER: 'bank_transfer',
  CREDIT_CARD: 'credit_card',
};
const PAYMENT_METHODS_FILTERED = objectHandler.filterByArray(
  PAYMENT_METHODS,
  process.env.VUE_APP_PAYMENT_METHODS_FILTER,
);
const PAYMENT = Object.freeze({
  METHODS: {
    ...PAYMENT_METHODS_FILTERED,
  },
  STATUS: {
    ACTIVE: 'active',
    COMPLETED: 'completed',
    COMPLETED_SETTLEMENT_IN_PROCESS: 'completed_settlement_in_process',
    DECLINED: 'declined',
    EXPIRED: 'expired',
    FAILED: 'failed',
    INACTIVE: 'inactive',
    PENDING: 'pending',
    UNKNOWN: 'unknown',
  },
});

export default Object.freeze({
  APIS_ICONS,
  COUNTRIES,
  ICONS,
  PAYMENT,
  POSITIONINGS,
  SORTINGS,
  STRIPE,
  TX,
});

import { AUTH, ROUTES, SUBSCRIPTION } from '@/constants';
import Vue from 'vue';
import VueRouter from 'vue-router';

import navigationGuard from './navigationGuard';
import { accountRoute } from './routes/account-route';
import { agreementsRoute } from './routes/agreements-route';
import { alertsRoute } from './routes/alerts-route';
import { apiRoute } from './routes/api-route';
import { appsRoute } from './routes/apps-route';
import { creditsRoute } from './routes/credits-route';
import { earnRoute } from './routes/earn-route';
import { eidRoute } from './routes/eid-route';
import { giftRoute } from './routes/gift-route';
import { loginRoute } from './routes/login-route';
import { membershipRoute } from './routes/membership-route';
import { purchaseRoute } from './routes/purchase-route';
import { yapilyRoute } from './routes/yapily-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES.PATH.ROOT,
    redirect: () => ROUTES.PATH.EID,
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
    },
  },
];

routes.push(
  accountRoute,
  agreementsRoute,
  apiRoute,
  appsRoute,
  creditsRoute,
  earnRoute,
  eidRoute,
  giftRoute,
  loginRoute,
  membershipRoute,
  purchaseRoute,
  yapilyRoute,
);
routes.push(...alertsRoute);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === AUTH.USER_ROLE.ANY) {
    next();
    return;
  }

  const userRole = await navigationGuard.setupAuthorization();
  if (userRole === AUTH.USER_ROLE.NOT_AUTHORIZED) {
    next(ROUTES.PATH.LOGIN);
    return;
  }

  await Promise.all([
    navigationGuard.setupAccount(),
    navigationGuard.setupApp(),
  ]);

  const hasContractsToSubscribe = navigationGuard.getHasContractsToSubscribe();
  if (hasContractsToSubscribe) {
    //TODO: set the right route
    next(ROUTES.PATH.SUBSCRIBE_AGREEMENTS);
    return;
  }

  const onBoardingStepUrl = navigationGuard.getOnBoardingStepUrl();
  if (onBoardingStepUrl) {
    window.location.replace(onBoardingStepUrl);
    return;
  }

  const status = navigationGuard.getSubscriptionStatus();
  if (to.meta.tags && to.meta.tags.indexOf(status) > -1) {
    next();
    return;
  } else {
    const route = navigationGuard.setupDefaultRoute(status);
    next(route);
    return;
  }
});

export default router;

export { AUTH, ROUTES, SUBSCRIPTION };

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

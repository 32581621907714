import { loadStripe } from '@stripe/stripe-js';
import { stripe } from '@/apis';
import { xLocale } from '@/locale';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cancelUrl
   * @param {String} membershipId
   * @param {String} productId
   * @param {Number} quantity
   * @param {String} referral
   * @param {String} successUrl
   */
  async payWithStripe(
    { commit, dispatch },
    {
      cancelUrl = '',
      membershipId = '',
      productId = '',
      quantity = 0,
      referral = '',
      successUrl = '',
    } = {},
  ) {
    commit('reset');
    commit('setLoading', true);
    const data = {
      cancel_url: cancelUrl,
      membership_id: membershipId,
      product_id: productId,
      quantity,
      referral,
      success_url: successUrl,
    };
    const sessionId = await dispatch('getStripeSessionId', data);
    await dispatch('redirectToStripeCheckout', sessionId);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async getStripeSessionId({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stripe.requestCreateSession({
        locale: xLocale,
        token,
        data,
      });
      return response.data.session_id;
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} sessionId
   */
  async redirectToStripeCheckout({ commit }, sessionId) {
    const stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
    if (!stripePublicKey || !sessionId) {
      return;
    }
    try {
      const stripe = await loadStripe(stripePublicKey);
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} sessionId
   */
  async fetchPayment({ commit, dispatch }, sessionId) {
    commit('setLoading', true);
    await dispatch('getPayment', sessionId);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} sessionId
   */
  async getPayment({ commit, rootGetters }, sessionId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stripe.requestPayment({
        token,
        sessionId,
      });
      commit('setPayment', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

const PATH = Object.freeze({
  ROOT: '/',
  LOGIN: '/login',
  GIFT: '/gift',
  NOT_FOUND: '/404',
  ACCOUNT: '/account',
  AGREEMENTS: '/agreements',
  AGREEMENTS_DASHBOARD: 'dasboard',
  AGREEMENTS_DETAIL: 'detail',
  API: '/api',
  APPS: '/apps',
  APPS_DASHBOARD: 'dashboard',
  CREDITS: '/credits',
  CREDITS_BUY: 'buy',
  CREDITS_BUY_CHOICE: 'choice',
  CREDITS_BUY_STATUS: 'status',
  CREDITS_DASHBOARD: 'dashboard',
  CREDITS_SEND: 'send',
  EARN: '/earn',
  EID: '/eid',
  MEMBERSHIP: '/membership',
  MEMBERSHIP_BENEFITS: 'extrabenefits',
  OUTPUT_CANCEL: '/output-cancel',
  OUTPUT_ERROR: '/output-error',
  OUTPUT_WAITING: '/output-waiting',
  PURCHASE: '/purchase',
  PURCHASE_CHOICE: 'choice',
  PURCHASE_CREDIT_CARD: 'credit-card',
  YAPILY: '/yapily',
  YAPILY_AUTH_ACCOUNT: 'auth-account',
  YAPILY_AUTH_PAYMENT: 'auth-payment',
  YAPILY_CONSENT: 'consent',
  YAPILY_CONSENT_CONFIRM: 'confirm',
  YAPILY_CONSENT_DONE: 'done',
  YAPILY_CONSENT_IMPACT: 'impact',
  YAPILY_CONSENT_INTRO: 'intro',
  YAPILY_START_PURCHASE: 'start-purchase',
});

const NAME = Object.freeze({
  NOT_FOUND: 'notFound',
  ACCOUNT: 'account',
  AGREEMENTS: 'agreements',
  AGREEMENTS_DASHBOARD: 'agreementsDasboard',
  AGREEMENTS_DETAIL: 'agreementsDetail',
  API: 'api',
  APPS: 'apps',
  APPS_DASHBOARD: 'appsDashboard',
  CREDITS: 'credits',
  CREDITS_BUY: 'creditsBuy',
  CREDITS_BUY_CHOICE: 'creditsBuyChoice',
  CREDITS_BUY_STATUS: 'creditsBuyStatus',
  CREDITS_DASHBOARD: 'creditsDashboard',
  CREDITS_SEND: 'creditsSend',
  EARN: 'earn',
  EID: 'eid',
  MEMBERSHIP: 'membership',
  MEMBERSHIP_BENEFITS: 'membershipExtraBenefits',
  OUTPUT_CANCEL: 'outputCancel',
  OUTPUT_ERROR: 'outputError',
  OUTPUT_WAITING: 'outputWaiting',
  PURCHASE: 'purchase',
  PURCHASE_CHOICE: 'purchaseChoice',
  PURCHASE_CREDIT_CARD: 'purchaseCreditCard',
  YAPILY: 'yapily',
  YAPILY_AUTH_ACCOUNT: 'yapilyAuthAccount',
  YAPILY_AUTH_PAYMENT: 'yapilyAuthPayment',
  YAPILY_CONSENT: 'yapilyConsent',
  YAPILY_CONSENT_CONFIRM: 'yapilyConsentConfirm',
  YAPILY_CONSENT_DONE: 'yapilyConsentDone',
  YAPILY_CONSENT_IMPACT: 'yapilyConsentImpact',
  YAPILY_CONSENT_INTRO: 'yapilyConsentIntro',
  YAPILY_START_PURCHASE: 'yapilyStartPurchase',
});

const TITLE = Object.freeze({
  NOT_FOUND: 'Not Found',
  ACCOUNT: 'Account',
  AGREEMENTS: 'Agreements',
  AGREEMENTS_DASHBOARD: 'Agreements Dashboard',
  AGREEMENTS_DETAIL: 'Agreement Detail',
  API: 'Api',
  APPS: 'Apps',
  APPS_DASHBOARD: 'Apps Dashboard',
  CREDITS: 'Credits',
  CREDITS_BUY: 'Credits Buy',
  CREDITS_BUY_CHOICE: 'Credits Buy Choice',
  CREDITS_BUY_STATUS: 'Credits Buy Status',
  CREDITS_DASHBOARD: 'Credits Dashboard',
  CREDITS_SEND: 'Credits Send',
  EARN: 'Earn',
  EID: 'Eid',
  MEMBERSHIP: 'Membership',
  MEMBERSHIP_BENEFITS: 'Membership Extra Benefits',
  OUTPUT_CANCEL: 'Cancel',
  OUTPUT_ERROR: 'Error',
  OUTPUT_WAITING: 'Waiting',
  PURCHASE: 'Purchase Start',
  PURCHASE_CHOICE: 'Purchase Choice',
  PURCHASE_CREDIT_CARD: 'Purchase Credit Card',
  YAPILY: 'Yapily',
  YAPILY_AUTH_ACCOUNT: 'Yapily Auth Account',
  YAPILY_AUTH_PAYMENT: 'Yapily Auth Payment',
  YAPILY_CONSENT: 'Yapily Consent',
  YAPILY_CONSENT_CONFIRM: 'Yapily Consent Confirm',
  YAPILY_CONSENT_DONE: 'Yapily Consent Done',
  YAPILY_CONSENT_IMPACT: 'Yapily Consent Impact',
  YAPILY_CONSENT_INTRO: 'Yapily Consent Intro',
  YAPILY_START_PURCHASE: 'Yapily Start Purchase',
});

export default Object.freeze({
  NAME,
  PATH,
  TITLE,
});

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAdding: (state) => state.isAdding,
  isSending: (state) => state.isSending,
  paging: (state) => state.paging,
  processes: (state) => state.processes,
  ccc: (state) => (state.tokens.length > 0 ? state.tokens[0] : null),
  cursor: (state) =>
    state.paging?.next_cursor ? state.paging.next_cursor : null,
};

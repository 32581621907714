import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

export default {
  /**
   *
   * @param {String} locale
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestCreateSession({ locale, token, data }) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Locale': locale,
    };
    const valued = apisHelper.bindValuedParams(data);
    return axios({
      url: '/session/create',
      method: 'post',
      baseURL: APIS.STRIPE,
      headers,
      data: valued,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} sessionId
   * @returns {AxiosPromise}
   */
  requestPayment({ token, sessionId }) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/session/${sessionId}/payment`,
      method: 'get',
      baseURL: APIS.STRIPE,
      headers,
    });
  },
};

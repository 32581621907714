import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';
import store from '@/store';

const PurchaseView = loadView('purchase/PurchaseView');
const PurchaseCreditCardView = loadView('purchase/PurchaseCreditCardView');

export const purchaseRoute = {
  path: ROUTES.PATH.PURCHASE,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.PURCHASE,
  },
  beforeEnter: (_to, _from, next) => {
    const identity = store.getters['account/identity'];
    const type = identity.subscription.membership.type;
    if (type === SUBSCRIPTION.MEMBERSHIP_TYPE.GREEN) {
      next();
    } else {
      next(ROUTES.PATH.EID);
    }
  },
  children: [
    {
      path: ``,
      name: ROUTES.NAME.PURCHASE,
      component: PurchaseView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.PURCHASE_CHOICE,
      name: ROUTES.NAME.PURCHASE_CHOICE,
      component: PurchaseView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.PURCHASE_CHOICE,
      },
    },
    {
      path: ROUTES.PATH.PURCHASE_CREDIT_CARD,
      name: ROUTES.NAME.PURCHASE_CREDIT_CARD,
      component: PurchaseCreditCardView,
      props: (route) => ({ ...route.query }),
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.PURCHASE_CREDIT_CARD,
      },
    },
  ],
};

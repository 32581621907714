import Vue from 'vue';
import App from './App.vue';
import i18n from './locale';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseColorPicker from '@/components/BaseColorPicker.vue';
import BaseCopyToClipboard from '@/components/BaseCopyToClipboard.vue';
import BaseDashboardCard from '@/components/BaseDashboardCard.vue';
import BaseListItem from '@/components/BaseListItem.vue';
import BaseListRow from '@/components/BaseListRow.vue';
import BaseLoadingCircular from '@/components/BaseLoadingCircular.vue';
import BaseLoadingLinear from '@/components/BaseLoadingLinear.vue';
import BaseModalConfirmAction from '@/components/modals/BaseModalConfirmAction.vue';
import BaseModalQRCode from '@/components/modals/BaseModalQRCode.vue';
import BaseSmallWindow from '@/components/BaseSmallWindow.vue';
import BaseSnackbar from '@/components/BaseSnackbar.vue';
import TheAlertNotice from '@/components/TheAlertNotice.vue';
import TheBalanceBox from '@/components/TheBalanceBox.vue';
import TheDashboardHeader from '@/components/TheDashboardHeader.vue';
import TheErrorMessage from '@/components/TheErrorMessage.vue';
import TheSearchBar from '@/components/search_bar/TheSearchBar.vue';

Vue.component('base-color-picker', BaseColorPicker);
Vue.component('base-copy-to-clipboard', BaseCopyToClipboard);
Vue.component('base-dashboard-card', BaseDashboardCard);
Vue.component('base-list-item', BaseListItem);
Vue.component('base-list-row', BaseListRow);
Vue.component('base-loading-circular', BaseLoadingCircular);
Vue.component('base-loading-linear', BaseLoadingLinear);
Vue.component('base-modal-confirm-action', BaseModalConfirmAction);
Vue.component('base-modal-qr-code', BaseModalQRCode);
Vue.component('base-small-window', BaseSmallWindow);
Vue.component('base-snackbar', BaseSnackbar);
Vue.component('the-alert-notice', TheAlertNotice);
Vue.component('the-balance-box', TheBalanceBox);
Vue.component('the-dashboard-header', TheDashboardHeader);
Vue.component('the-error-message', TheErrorMessage);
Vue.component('the-search-bar', TheSearchBar);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

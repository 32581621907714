export const msgs = {
  acceptAdditional: 'I have read and specifically accept the Additional Terms.',
  acceptError: 'To continue you must accept the contract',
  acceptNodeAdditional: 'I acknowledge that I have read and specifically accept the Additional Terms of the Distributor Node Agreement',
  acceptNodeTos: 'I acknowledge that I have read and accept the terms of the Distributor Node Agreement',
  acceptTos: 'I acknowledge that I have read the Agreement for the purchase of technology goods and services ("Agreement") General Terms and Conditions and accept the terms.',
  accountNotMatch: 'The account entered does not match the user',
  addressError: 'Incorrect address',
  alphaNumField: 'This field accepts only alphanumeric characters',
  amountError: 'This is not a valid amount',
  apiEmptyList: 'No API found',
  cashBackBonus: 'cash back in credits',
  codeLengthError: 'Min 5 and max 16 characters',
  completedConsentCancellation: 'You are no longer providing us access to your banking data',
  configuringData: 'Please wait, we are configuring your data',
  configuringError: 'An error occurred during configuration, contact the administrator',
  confirmRevokeConsent: 'Are you sure you want to cancel permission to access this account?',
  copied: 'Copied',
  credentialsEmptyList: 'No credential found',
  creditsBuyFooter: 'Warning: all Credits/token transaction are final and cannot be reversed',
  creditsSendFooter: 'Warning: all Credits/token transaction are final and cannot be reversed',
  deleteAccount: '<strong>Account Deletion Notice</strong><br><br>If you wish to request the deletion of your account, please note that this action will result in the permanent loss of all data associated with your profile. This includes, among other things, any tokens stored in your wallet and the link to all past transactions and operations. Once deleted, your account cannot be restored in any way.<br>Please be aware that the deletion is final, and the request, once submitted, cannot be reversed. The deletion process will be carried out within 15 days following your request.<br>Make sure to save any important information before proceeding.',
  description: 'Description',
  docsLoseAssociation: 'Number of documents that will remain without association:',
  earnShare: 'Give $25 to all companies by sharing this link and you will receive $25 too in free credits when they will purchase a subscription.',
  emailField: 'This field accepts only valid email addresses',
  enterTheFollowingToConfirm: 'Enter {0} to confirm',
  errorOccurred: 'Sorry, but an error occurred',
  fiscalCodeError: 'Incorrect fiscal code',
  mandatoryField: 'This field is mandatory',
  membershipBenefits:'The service consists of a one-off free variable allocation of Trade Cash Credits CCC for promotional purposes that can be used for the sole purpose of testing the services.',
  membershipBenefitsIntro: 'Access to this experimental service is granted for a temporary period and can be removed at any time. At this time, COMMERC.IO offers the following Extra Benefits:',
  missingConsentAlert: 'You will not be able to proceed with payments without renewing your consent for access to basic account data and consent for the new payment.',
  modalQrCodeDesc: 'This is you public Identity, share it with anyone you want to do business',
  modalQrWalletDesc: 'This is your Wallet , share it with anyone you want to get paid',
  name: 'Name',
  noCode: 'No code',
  noMessage: 'No message',
  notAvailable: 'Not available',
  notFound: 'Not found',
  operationDeleted: 'Operation deleted',
  pageNotFound: 'Page not found',
  paidFeature: 'This feature is active only with Bronze membership or higher',
  paymentCheck: 'Please wait, we are verifying the payment and configuring your data',
  paymentDeleted: 'Payment has been stopped',
  paymentError: 'Process failed, contact administrator',
  paymentProcessRunning: 'Please wait the payment process is running',
  paymentSuccessful: 'Thanks for your payment. The transaction has been completed',
  purchaseAuthAccount: 'Account authenticated, you are about to be directed to obtain payment authorization',
  purchaseByCreditCard:'You are about to be directed to pay by credit card, confirm to continue',
  purchaseMembership: 'Use it, invite your contacts and earn a Monthly Income',
  purchaseTokenCost: 'Purchase token cost',
  requestSentSuccessfully: 'Request sent successfully, you will receive a confirmation email shortly',
  sdiLengthError: 'The SDI code is a 7-digit alphanumeric sequence',
  sendingConfirm: 'Do you confirm the sending?',
  sendingTokens: 'Please wait, we are sending the purchased CCCs to your wallet',
  sendingTokensCompleted: 'Sending of CCC completed successfully',
  sendingTokensFailed: 'Sending of CCC failed, please print the summary and contact administrator',
  serviceTemporarilyUnavailable: "Service temporarily unavailable",
  startTypingToFilterCountries: 'Start typing to filter countries',
  startTypingToSearch: 'Start typing to search',
  textLengthError: 'Min 3 and max 256 characters',
  triggeredAnError: 'Something happened in setting up the request that triggered an error',
  txsEmptyList: 'No transaction found',
  unansweredRequest: 'The request was made but no response was received',
  vatError: 'Incorrect VAT number',
  vatLengthError: 'Min 5 and max 15 characters',
};

import { YAPILY } from '@/constants';
import { faker } from '@faker-js/faker';

const mockConsent = () => {
  const item = {
    authorized_at: '2024-07-31T08:22:02.197Z',
    created_at: '2024-08-02T08:53:16.187Z',
    expires_at: '2025-08-02T08:53:16.187Z',
    feature_scope: ['INITIATE_PRE_AUTHORISATION'],
    id: faker.string.uuid(),
    institution_consent_id: faker.string.uuid(),
    institution_id: 'modelo-sandbox',
    institution_name: 'Modelo Sandbox',
    is_deleted_by_institution: false,
    last_confirmed_at: '2024-07-31T08:22:02.197Z',
    reconfirm_by: '2025-02-02T08:53:16.187Z',
    reference_id: faker.string.uuid(),
    status: 'AUTHORIZED',
    time_to_expire: '100000s',
  };
  return item;
};

const mockConsents = (consents = 3) =>
  faker.helpers.multiple(() => mockConsent(), { count: consents });

const mockInstitution = (psu = 'PSU-ID') => {
  const item = {
    countries: [
      {
        country_code_2: 'GB',
        display_name: 'United Kingdom',
      },
    ],
    credentials_type: 'OPEN_BANKING_UK_AUTO',
    environment_type: 'SANDBOX',
    full_name: 'Modelo Sandbox',
    id: 'modelo-sandbox',
    media: [
      {
        source:
          'https://images.yapily.com/image/ca502f24-d6df-4785-b4b8-1034b100af77?size=0',
        type: 'logo',
      },
    ],
    name: 'Modelo Sandbox',
  };
  if (psu) {
    item.psu = psu;
  }
  return item;
};

const mockInstitutions = (institutions = 3) =>
  faker.helpers.multiple(() => mockInstitution(), { count: institutions });

const mockPaymentSession = (
  checkoutStatus = YAPILY.PAYMENT.STATUS.ACCEPTED,
) => {
  const item = {
    checkout_status: checkoutStatus,
    payee: {
      account_identifications: [
        {
          identification: 'GB33BUKB20201555555555',
          type: 'string',
        },
      ],
      address: {
        address_lines: '1 High Street',
        address_type: 'string',
        building_number: '1',
        country: 'GB',
        county: 'Rome',
        department: 'string',
        post_code: '37000',
        street_name: 'High Street',
        sub_department: 'string',
        town_name: 'London',
      },
      merchant_category_code: 'string',
      merchant_id: 'string',
      name: 'John Doe',
    },
    payer: {
      account_identifications: [
        {
          identification: 'GB33BUKB20201555555555',
          type: 'string',
        },
      ],
      address: {
        address_lines: '1 High Street',
        address_type: 'string',
        building_number: '1',
        country: 'GB',
        county: 'Rome',
        department: 'string',
        post_code: '37000',
        street_name: 'High Street',
        sub_department: 'string',
        town_name: 'London',
      },
      name: 'John Doe',
    },
    payment_amount: '100.00',
    payment_currency: 'EUR',
    payment_id: faker.string.uuid(),
    payment_reference: 'Commercio Network payment',
    payment_status: 'COMPLETED',
  };
  return item;
};

export {
  mockConsent,
  mockConsents,
  mockInstitution,
  mockInstitutions,
  mockPaymentSession,
};

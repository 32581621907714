import { bankHandler } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  consentDetail: (state) => state.consentDetail,
  consentList: (state) => state.consentList,
  consentPaging: (state) => state.consentPaging,
  institutions: (state) => state.institutions,
  session: (state) => state.session,
  countries: (state) => bankHandler.getCountries(state.institutions),
  consentCursor: (state) =>
    state.consentPaging?.next_cursor ? state.consentPaging.next_cursor : null,
};

import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const AgreementsViewDashboard = loadView('agreements/AgreementsViewDashboard');
const AgreementsViewDetail = loadView('agreements/AgreementsViewDetail');

export const agreementsRoute = {
  path: ROUTES.PATH.AGREEMENTS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.AGREEMENTS,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.AGREEMENTS,
      component: AgreementsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.AGREEMENTS_DASHBOARD,
      name: ROUTES.NAME.AGREEMENTS_DASHBOARD,
      component: AgreementsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.AGREEMENTS_DASHBOARD,
      },
    },
    {
      path: `${ROUTES.PATH.AGREEMENTS_DETAIL}/:id`,
      name: ROUTES.NAME.AGREEMENTS_DETAIL,
      component: AgreementsViewDetail,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.AGREEMENTS_DETAIL,
      },
    },
  ],
};

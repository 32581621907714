import { eid, membership } from '@/apis';
import { xLocale } from '@/locale';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initPurchaseDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchMemberships'),
      dispatch('account/fetchIdentity', null, { root: true }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchMemberships({ commit }) {
    try {
      const response = await membership.requestList(xLocale, true);
      commit('setMemberships', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Array.<Object>} contracts
   * @param {Object} data
   * @returns {Promise<boolean>}
   */
  async updateBuyerData({ commit, dispatch }, { contracts, data }) {
    commit('setLoading', true);
    const requests = [
      dispatch('updateAgreements', contracts),
      dispatch('updateIdentity', data),
    ];
    await Promise.all(requests);
    await dispatch('account/fetchIdentity', null, { root: true });
    commit('setLoading', false);
    return true;
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Array.<Object>} contracts
   */
  async updateAgreements({ commit, rootGetters }, contracts) {
    if (!contracts.length) {
      return;
    }
    const token = rootGetters['auth/token'];
    try {
      await eid.requestUpdateContracts({
        locale: xLocale,
        token,
        data: { contracts },
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async updateIdentity({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    try {
      await eid.requestUpdate({
        locale: xLocale,
        token,
        data,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} membershipId
   */
  async initPurchaseByCreditCard({ commit, dispatch }, membershipId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchMembership', membershipId)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {String} membershipId
   */
  async fetchMembership({ commit }, membershipId) {
    try {
      const response = await membership.requestDetail({
        locale: xLocale,
        membershipId,
      });
      commit('setMembership', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

export const titles = {
  account: 'Account',
  agreementAdditionalNodeTerms: 'DISTRIBUTOR NODE AGREEMENT Additional Terms',
  agreementAdditionalTerms: 'Additional Terms',
  agreementBuyService: 'Agreement for the purchase of technology goods and services ("Agreement") General terms and conditions',
  agreementNodeDistributor: 'DISTRIBUTOR NODE AGREEMENT (hereinafter "Agreement") General terms and conditions Version nr. 1 / publication date 15 April 2024',
  agreements: 'Agreements',
  allCredentials: 'All credentials',
  allDocumentsCategories: 'All documents categories',
  appsGallery: 'Apps Gallery',
  availableApi: 'Available API',
  availableBanks: 'Available banks',
  buy: 'Buy',
  cancellationCompleted: 'Cancellation completed',
  code: 'Code',
  confirmCancellation: 'Confirm cancellation',
  confirmRequest: 'Confirm request',
  creditCardPayment: 'Credit card payment',
  creditsAllTransactions: 'All transactions',
  creditsBuyNow: 'Buy credits now',
  creditsReceivedTransactions: 'Received transactions',
  creditsSendNow: 'Send credits now',
  creditsSentTransactions: 'Sent transactions',
  deleteAccount: 'Delete account',
  details: 'Details',
  documents: 'Documents',
  helpUsImprove: 'Help us improve',
  inviteACompany: 'Invite a Company',
  invoicingData: 'Invoicing data',
  joinTheFirst: 'Join the first',
  lastDocumentsShared: 'Last documents shared',
  message: 'Message',
  metadata: 'Metadata',
  organizationEid: 'Your organization eID',
  payeeDetails: 'Payee Details',
  payerDetails: 'Payer Details',
  paymentMethod: 'Payment method',
  paymentTotal: 'Payment Total',
  permissionDetail: 'Permission detail',
  purchaseMembership: 'Purchase annual membership',
  recap: 'Recap',
  reminder: 'Reminder',
  sharedocSendNow:'Send a Sharedoc now',
  stripe: 'Stripe',
  success: 'Success',
  summary: 'Summary',
  swapTokens: 'Swap tokens',
  theImpactToYourService: 'The impact to your service',
  whatCanDo: 'What can i do with commercio.network membership?',
  yapily: 'Yapily',
  yapilyConsents: 'Yapily consents',
  yourWallet: 'Your Wallet',
};

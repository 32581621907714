import { credits, products } from '@/apis';
import { LISTINGS, PRODUCTS } from '@/constants';
import { xLocale } from '@/locale';

export default {
  /**
   *
   * @param {Function} dispatch
   * @param {String} filter
   */
  async initCreditsDashboard({ dispatch }, filter) {
    const requests = [
      dispatch('fetchCreditsDashboardData', filter),
      dispatch('header/initHeader', null, { root: true }),
    ];
    await Promise.all(requests);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} filter
   */
  async fetchCreditsDashboardData({ commit, dispatch }, filter) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('getCreditsList', { filter })];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} cursor
   * @param {String} filter
   */
  async addCredits({ commit, dispatch }, { cursor, filter }) {
    commit('setAdding', true);
    await dispatch('getCreditsList', { cursor, filter });
    commit('setAdding', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   * @param {String} filter
   */
  async getCreditsList(
    { commit, rootGetters },
    { cursor, filter, order = LISTINGS.SORTINGS.DESC } = {},
  ) {
    const token = rootGetters['auth/token'];
    const request = setRequest(filter);
    try {
      const response = await credits[request]({
        token,
        cursor,
        order,
      });
      commit('addProcesses', response.data.processes);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   * @returns {Promise<String>}
   */
  async sendCredits({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    commit('setSending', true);
    try {
      const response = await credits.requestSend(token, data);
      return response.data.send_credits_id;
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setSending', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initCreditsBuy({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchTokens')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchTokens({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await products.requestList({
        token,
        locale: xLocale,
        category: PRODUCTS.CATEGORY.PLATFORM_CREDITS,
      });
      commit('setTokens', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

/**
 *
 * @param {String} type
 */
const setRequest = (type) => {
  switch (type) {
    case LISTINGS.TX.TYPE.RECEIVED:
      return 'requestReceivedList';
    case LISTINGS.TX.TYPE.SENT:
      return 'requestSentList';
    default:
      return 'requestList';
  }
};

export const titles = {
  account: 'Account',
  agreementAdditionalNodeTerms: 'Termini Addizionali ACCORDO NODO DISTRIBUTORE',
  agreementAdditionalTerms: 'Termini Addizionali',
  agreementBuyService: 'Accordo di acquisto di beni e servizi tecnologici (“Accordo”) Termini e condizioni generali',
  agreementNodeDistributor: 'ACCORDO NODO DISTRIBUTORE (di seguito “Accordo“) Termini e condizioni generali Versione nr. 1 /data pubblicazione 15 Aprile 2024',
  agreements: 'Contratti',
  allCredentials: 'Tutte le credenziali',
  allDocumentsCategories: 'Tutte le categorie di documenti',
  appsGallery: 'Apps Gallery',
  availableApi: 'API disponibili',
  availableBanks: 'Banche disponibili',
  buy: 'Acquisto',
  cancellationCompleted: 'Cancellazione completata',
  code: 'Codice',
  confirmCancellation: 'Conferma cancellazione',
  confirmRequest: 'Conferma richiesta',
  creditCardPayment: 'Pagamento con carta di credito',
  creditsAllTransactions: 'Tutte le transazioni',
  creditsBuyNow: 'Acquista crediti adesso',
  creditsReceivedTransactions: 'Transazioni ricevute',
  creditsSendNow: 'Invia crediti adesso',
  creditsSentTransactions: 'Transazioni inviate',
  deleteAccount: 'Cancella account',
  details: 'Dettagli',
  documents: 'Documenti',
  helpUsImprove: 'Aiutaci a migliorare',
  inviteACompany: 'Invita un\'Azienda',
  invoicingData: 'Dati fatturazione',
  joinTheFirst: 'Entra a far parte del primo',
  lastDocumentsShared: 'Ultimi documenti condivisi',
  message: 'Messaggio',
  metadata: 'Metadata',
  organizationEid: 'eID della tua organizzazione',
  payeeDetails: 'Dettagli del beneficiario',
  payerDetails: 'Dettagli del pagante',
  paymentMethod: 'Metodo di pagamento',
  paymentTotal: 'Totale pagamento',
  permissionDetail: 'Dettaglio permessi',
  purchaseMembership: 'Acquista la membership annuale',
  recap: 'Riepilogo',
  reminder: 'Promemoria',
  sharedocSendNow:'Invia una Sharedoc adesso',
  stripe: 'Stripe',
  success: 'Successo',
  summary: 'Sommario',
  swapTokens: 'Scambio tokens',
  theImpactToYourService: 'L’impatto sul tuo servizio',
  whatCanDo: 'Cosa potro’ fare con la membership di commercio.network?',
  yapily: 'Yapily',
  yapilyConsents: 'Consensi a Yapily',
  yourWallet: 'Il tuo Wallet',
};

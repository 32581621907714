import { APIS } from '@/constants';
import axios from 'axios';

export default {
  /**
   *
   * @param {String} locale
   * @param {Boolean} onlyBuyable
   * @returns {AxiosPromise}
   */
  requestList(locale, onlyBuyable = false) {
    const headers = {
      'X-Locale': locale,
    };
    const params = {
      only_buyable: onlyBuyable,
    };
    return axios({
      method: 'get',
      baseURL: APIS.MEMBERSHIP,
      headers,
      params,
    });
  },
  /**
   *
   * @param {String} locale
   * @param {String} membershipId
   * @returns {AxiosPromise}
   */
  requestDetail({ locale, membershipId }) {
    const headers = {
      'X-Locale': locale,
    };
    return axios({
      url: `/${membershipId}`,
      method: 'get',
      baseURL: APIS.MEMBERSHIP,
      headers,
    });
  },
};

import { LISTINGS } from '@/constants';
import { faker } from '@faker-js/faker';

const statuses = Object.values(LISTINGS.TX.MESSAGE_STATUS);

const mockPayment = () => {
  const apiStatus = faker.helpers.arrayElement(statuses);
  const item = {
    amount_paid: '1022',
    api_services_process_error:
      apiStatus === LISTINGS.TX.MESSAGE_STATUS.FAILURE
        ? 'bastion could not sign the messages'
        : undefined,
    api_services_process_status: apiStatus,
    created_at: '2024-01-31T11:34:38+01:00',
    paid_at: '2024-01-31T11:34:38+01:00',
    paid_currency: 'EUR',
    payment_id: faker.string.uuid(),
    process_id: faker.string.uuid(),
    product_quantity: 10,
    status: 'paid',
  };
  return item;
};

export { mockPayment };

import { yapily } from '@/apis';
import { ROUTES, SETTINGS } from '@/constants';
import { urlParser } from '@/utils';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initYapilyPayment({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchInstitutions')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchInstitutions({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await yapily.requestInstitutions(token);
      commit('addInstitutions', response.data.institutions);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} institutionId
   * @param {String} membershipId
   * @param {String} psu
   */
  async authorizeAccount(
    { commit, rootGetters },
    { institutionId, membershipId, psu },
  ) {
    commit('setLoading', true);
    const token = rootGetters['auth/token'];
    const data = {
      account_redirect_url: `${SETTINGS.HOST}${ROUTES.PATH.YAPILY}/${ROUTES.PATH.YAPILY_AUTH_ACCOUNT}`,
      institution_id: institutionId,
      membership_id: membershipId,
      payment_redirect_url: `${SETTINGS.HOST}${ROUTES.PATH.YAPILY}/${ROUTES.PATH.YAPILY_AUTH_PAYMENT}`,
      psu,
    };
    try {
      const response = await yapily.requestAccountAuth(token, data);
      window.location.replace(response.data.consent_url);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} query
   */
  analyzeQuery({ commit }, query) {
    try {
      urlParser.scanQueryForErrors(query);
      return true;
    } catch (error) {
      commit('setError', error);
      return false;
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} sessionId
   */
  async fetchPaymentSession({ commit, rootGetters }, sessionId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await yapily.requestPaymentSession(token, sessionId);
      commit('setSession', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} query
   * @param {String} sessionId
   */
  async initAuthAccount({ commit, dispatch }, { query, sessionId }) {
    commit('reset');
    commit('setLoading', true);
    const res = dispatch('analyzeQuery', query);
    if (!res) {
      commit('setLoading', false);
      return;
    }
    const requests = [
      dispatch('fetchInstitutions'),
      dispatch('fetchPaymentSession', sessionId),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} consent
   * @param {String} sessionId
   */
  async authorizePayment({ commit, rootGetters }, { consent, sessionId }) {
    commit('setLoading', true);
    const token = rootGetters['auth/token'];
    const data = {
      account_consent_token: consent,
      session_id: sessionId,
    };
    try {
      const response = await yapily.requestPaymentAuth(token, data);
      window.location.replace(response.data.consent_url);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Object} query
   * @param {String} sessionId
   */
  async initAuthPayment({ commit, dispatch }, { query, sessionId }) {
    commit('reset');
    commit('setLoading', true);
    const res = dispatch('analyzeQuery', query);
    if (!res) {
      commit('setLoading', false);
      return;
    }
    const consent = query.consent;
    await dispatch('confirmBankTransfer', { consent, sessionId });
    await dispatch('account/fetchIdentity', null, { root: true });
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} consent
   * @param {String} sessionId
   */
  async confirmBankTransfer({ commit, rootGetters }, { consent, sessionId }) {
    const token = rootGetters['auth/token'];
    const data = {
      payment_consent_token: consent,
      session_id: sessionId,
    };
    try {
      const response = await yapily.requestConfirmPayment(token, data);
      commit('setSession', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} cursor
   */
  async fetchConsentList({ commit, rootGetters }, cursor) {
    const token = rootGetters['auth/token'];
    try {
      const response = await yapily.requestConsentList(token, cursor);
      commit('addConsentList', response.data.consents);
      commit('setConsentPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} sessconsentIdionId
   */
  async initConsentDetail({ commit, dispatch }, consentId) {
    commit('reset');
    commit('setLoading', true);
    await dispatch('fetchConsentDetail', consentId);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} consentId
   */
  async fetchConsentDetail({ commit, rootGetters }, consentId) {
    const token = rootGetters['auth/token'];
    try {
      const response = await yapily.requestConsentDetail(token, consentId);
      commit('setConsentDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} consentId
   */
  async confirmRemoveConsent({ commit, dispatch }, consentId) {
    commit('setLoading', true);
    const res = await dispatch('removeConsent', consentId);
    commit('setLoading', false);
    return res;
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} consentId
   */
  async removeConsent({ commit, rootGetters }, consentId) {
    const token = rootGetters['auth/token'];
    try {
      await yapily.requestRevokeConsent(token, consentId);
      return true;
    } catch (error) {
      commit('setError', error);
    }
  },
};

import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const YapilyAuthAccountView = loadView('yapily/YapilyAuthAccountView');
const YapilyAuthPaymentView = loadView('yapily/YapilyAuthPaymentView');
const YapilyConsentConfirmView = loadView(
  'yapily/consent/YapilyConsentConfirmView',
);
const YapilyConsentDoneView = loadView('yapily/consent/YapilyConsentDoneView');
const YapilyConsentImpactView = loadView(
  'yapily/consent/YapilyConsentImpactView',
);
const YapilyConsentIntroView = loadView(
  'yapily/consent/YapilyConsentIntroView',
);
const YapilyConsentView = loadView('yapily/YapilyConsentView');
const YapilyStartPurchaseView = loadView('yapily/YapilyStartPurchaseView');

export const yapilyRoute = {
  path: ROUTES.PATH.YAPILY,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.YAPILY,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.YAPILY,
      component: YapilyStartPurchaseView,
      props: (route) => ({ ...route.query }),
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: `${ROUTES.PATH.YAPILY_AUTH_ACCOUNT}/:sessionId`,
      name: ROUTES.NAME.YAPILY_AUTH_ACCOUNT,
      component: YapilyAuthAccountView,
      props: (route) => ({ ...route.query, ...route.params }),
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.YAPILY_AUTH_ACCOUNT,
      },
    },
    {
      path: `${ROUTES.PATH.YAPILY_AUTH_PAYMENT}/:sessionId`,
      name: ROUTES.NAME.YAPILY_AUTH_PAYMENT,
      component: YapilyAuthPaymentView,
      props: (route) => ({ ...route.query, ...route.params }),
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.YAPILY_AUTH_PAYMENT,
      },
    },
    {
      path: `${ROUTES.PATH.YAPILY_CONSENT}/:consentId`,
      component: YapilyConsentView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.YAPILY_CONSENT,
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.YAPILY_CONSENT,
          component: YapilyConsentIntroView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.YAPILY_CONSENT_CONFIRM,
          name: ROUTES.NAME.YAPILY_CONSENT_CONFIRM,
          component: YapilyConsentConfirmView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.YAPILY_CONSENT_CONFIRM,
          },
        },
        {
          path: ROUTES.PATH.YAPILY_CONSENT_DONE,
          name: ROUTES.NAME.YAPILY_CONSENT_DONE,
          component: YapilyConsentDoneView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.YAPILY_CONSENT_DONE,
          },
        },
        {
          path: ROUTES.PATH.YAPILY_CONSENT_IMPACT,
          name: ROUTES.NAME.YAPILY_CONSENT_IMPACT,
          component: YapilyConsentImpactView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.YAPILY_CONSENT_IMPACT,
          },
        },
        {
          path: ROUTES.PATH.YAPILY_CONSENT_INTRO,
          name: ROUTES.NAME.YAPILY_CONSENT_INTRO,
          component: YapilyConsentIntroView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.YAPILY_CONSENT_INTRO,
          },
        },
      ],
    },
    {
      path: ROUTES.PATH.YAPILY_START_PURCHASE,
      name: ROUTES.NAME.YAPILY_START_PURCHASE,
      component: YapilyStartPurchaseView,
      props: (route) => ({ ...route.query }),
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.YAPILY_START_PURCHASE,
      },
    },
  ],
};

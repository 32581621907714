const objectHandler = {
  /**
   *
   * @param {Object} obj
   * @param {Array.<String>} arr
   * @returns {Object}
   */
  filterByArray(obj, arr) {
    return Object.keys(obj).reduce((acc, key) => {
      if (arr.includes(obj[key])) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  },
};

export default objectHandler;

import { APIS } from '@/constants';
import axios from 'axios';

export default {
  /**
   *
   * @param {String} locale
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestDetail(locale, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Locale': locale,
    };
    return axios({
      url: APIS.EID,
      method: 'get',
      headers,
    });
  },
  /**
   *
   * @param {String} locale
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestUpdate({ locale, token, data }) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Locale': locale,
    };
    return axios({
      url: APIS.EID,
      method: 'put',
      headers,
      data: data,
    });
  },
  /**
   *
   * @param {String} locale
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestUpdateContracts({ locale, token, data }) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Locale': locale,
    };
    return axios({
      url: '/contracts',
      method: 'put',
      baseURL: APIS.EID,
      headers,
      data: data,
    });
  },
  /**
   *
   * @param {String} locale
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestDelete(locale, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Locale': locale,
    };
    return axios({
      url: '/delete',
      method: 'get',
      baseURL: APIS.EID,
      headers,
    });
  },
};

const COUNTRY = Object.freeze({
  GB: 'GB',
  IT: 'IT',
});

const PSU = Object.freeze({
  IDENTIFIER: {
    PSU_CORPORATE_ID: 'PSU-CORPORATE-ID',
    PSU_ID: 'PSU-ID',
    PSU_IP_ADDRESS: 'PSU-IP-ADDRESS',
  },
});

const QUERY_PARAMS = Object.freeze({
  FAILURE: {
    APPLICATION_USER_ID: 'application-user-id',
    ERROR: 'error',
    ERROR_DESCRIPTION: 'error-description',
    ERROR_SOURCE: 'error-source',
    INSTITUTION: 'institution',
    USER_UUID: 'user-uuid',
  },
  SUCCESS: {
    APPLICATION_USER_ID: 'application-user-id',
    CONSENT: 'consent',
    INSTITUTION: 'institution',
    USER_UUID: 'user-uuid',
  },
});

const ERROR = Object.freeze({
  BAD_REQUEST: 'bad_request',
  INTERNAL_SERVER_ERROR: 'internal_server_error',
  SERVICE_UNAVAILABLE: 'service_unavailable',
  UNAUTHORIZED: 'unauthorized',
});

const ERROR_CODE = Object.freeze({
  [ERROR.BAD_REQUEST]: 400,
  [ERROR.INTERNAL_SERVER_ERROR]: 500,
  [ERROR.SERVICE_UNAVAILABLE]: 503,
  [ERROR.UNAUTHORIZED]: 401,
});

const ERROR_MESSAGE = Object.freeze({
  [ERROR.BAD_REQUEST]: 'Bad Request',
  [ERROR.INTERNAL_SERVER_ERROR]: 'Internal Server Error',
  [ERROR.SERVICE_UNAVAILABLE]: 'Service Unavailable',
  [ERROR.UNAUTHORIZED]: 'Unauthorized',
});

const ERROR_SOURCES = Object.freeze({
  [ERROR.BAD_REQUEST]: [
    'request_not_supported',
    'request_uri_not_supported',
    'invalid_request',
    'registration_not_supported',
    'invalid_scope',
    'invalid_client',
  ],
  [ERROR.INTERNAL_SERVER_ERROR]: ['server_error'],
  [ERROR.SERVICE_UNAVAILABLE]: ['temporarily_unavailable'],
  [ERROR.UNAUTHORIZED]: [
    'access_denied',
    'account_selection_required',
    'consent_required',
    'interaction_required',
    'invalid_grant',
    'login_required',
    'unauthorized_client',
    'unsupported_grant_type',
    'user_cancelled',
  ],
});

const PAYMENT = Object.freeze({
  STATUS: {
    ACCEPTED: 'accepted',
    AWAITING_ACCOUNT_AUTHORIZATION: 'awaiting_account_authorization',
    AWAITING_PAYMENT_AUTHORIZATION: 'awaiting_payment_authorization',
    AWAITING_PAYMENT_CONFIRM: 'awaiting_payment_confirm',
    COMPLETED: 'completed',
    EXPIRED: 'expired',
    FAILED: 'failed',
    PAYMENT_CONFIRMED: 'payment_confirmed',
  },
});

const TERMS = Object.freeze({
  DATA_ACCESS({ bank, country, date }) {
    switch (country) {
      case COUNTRY.IT:
        return `<p>Abbiamo stretto una partnership con Yapily Connect per accedere ai vostri dati bancari presso ${bank}.</p><p>Ora sarete reindirizzati in modo sicuro a ${bank} per accedere alle seguenti informazioni:</p><ul><li>Dettagli account</li><li>Bilanci</li></ul><br><p>Utilizzando il servizio, l'utente accetta che Yapily Connect acceda ai suoi dati bancari, <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">Terms & Conditions</a> e <a href="https://www.yapily.com/legal/privacy-policy" target="_blank">Privacy Notice</a>.</p><p>Il presente consenso sarà valido fino alla data ${date}</p>`;
      default:
        return `<p>We have partnered with Yapily Connect to access your bank data at ${bank}.</p><p>You will now be securely redirected to ${bank} to give access to the following information:</p><ul><li>Account(s) details</li><li>Balances</li></ul><br><p>By using the service, you agree to Yapily Connect accessing your bank data, the <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">Terms & Conditions</a> and <a href="https://www.yapily.com/legal/privacy-policy" target="_blank">Privacy Notice</a>.</p><p>This consent will be valid until ${date}</p>`;
    }
  },
  PAYMENT_EXEC({ bank, country }) {
    switch (country) {
      case COUNTRY.IT:
        return {
          intro: `<p>Collaboriamo con Yapily Connect per avviare in modo sicuro il pagamento dal tuo conto presso ${bank}.</p>`,
          consent: `<p>Utilizzando il servizio, accetti che Yapily Connect avvii questo pagamento e i suoi <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">Termini e Condizioni</a> e <a href="https://www.yapily.com/legal/privacy-policy" target="_blank">Informativa sulla Privacy</a>.</p>`,
        };
      default:
        return {
          intro: `<p>We have partnered with Yapily Connect to securely initiate payment from your account at ${bank}.</p>`,
          consent: `<p>By using the service, you agree to Yapily Connect initiating this payment and its <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">Terms & Conditions</a> and <a href="https://www.yapily.com/legal/privacy-policy" target="_blank">Privacy Notice</a>.</p>`,
        };
    }
  },
});

export default Object.freeze({
  COUNTRY,
  ERROR,
  ERROR_CODE,
  ERROR_MESSAGE,
  ERROR_SOURCES,
  PAYMENT,
  PSU,
  QUERY_PARAMS,
  TERMS,
});
